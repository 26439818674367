@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply scrollbar-hide bg-white overflow-hidden break-keep text-[22px] font-semibold leading-[1.25] text-primary antialiased lg:text-xl;
  }
  main {
    @apply mb-24 mt-10 flex flex-col gap-24 lg:mx-auto lg:mb-32 lg:mt-20 lg:w-[740px] lg:gap-32 selection:bg-[#1b9bcf] selection:text-[#ffffff];
  }
}

@layer components {
  #root {
    @apply h-screen w-screen overflow-y-scroll;
  }
  #lottie {
    @apply pointer-events-none fixed left-1/2 -z-10 -translate-x-1/2 scale-[1.07] lg:top-1/2 lg:w-screen lg:-translate-y-1/2;
  }
}

.fill_bg_color {
  fill: #ffffff;
}
.fill_type_color {
  fill: #1b9bcf;
}
.fill_lottie_color {
  fill: #1b9bcf;
}
